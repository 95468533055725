import React, { useState } from "react"
import PropTypes from "prop-types"

import HeadingText from "@components/common/HeadingText"
import BrandButton from "@components/common/BrandButton"

import NewsBannerCard from "../NewsBannerCard"
import FilterSelectNavigator from "@components/common/FilterSelectNavigator"
import BodyText from "@components/common/BodyText"

import language from "@language"

import * as c from "@utilities/constants"

import "./style.scss"

const NewsFilterSection = props => {
  const INCREMENT_BY = 10
  const [categoryFilterId, setCategoryFilterId] = useState(null)
  const [numberToShow, setNumberToShow] = useState(INCREMENT_BY)

  const resetViewNumber = () => {
    setNumberToShow(INCREMENT_BY)
  }

  const filterNews = React.useMemo(() => {
    return props.news.filter(newsPost => {
      // filter by location filter
      if (categoryFilterId) {
        if (!newsPost.acf.category) return false
        return newsPost.acf.category.id === categoryFilterId
      } else {
        return newsPost
      }
    })
  }, [categoryFilterId, props.news])

  const handleSetCategoryFilterId = option => {
    const value = (option || {}).value

    if (value !== categoryFilterId) {
      resetViewNumber()
      setCategoryFilterId(value)
    }
  }

  const handleViewMore = () => {
    setNumberToShow(numberToShow + INCREMENT_BY)
  }

  const options = props.newsCategories.map(category => {
    return { value: category.id, label: category.name }
  })
  return (
    <div className="news-filter-section">
      <div className="page-wrap">
        <div className="news-filter-section__filter-row">
          <HeadingText
            className="news-filter-section__title"
            size="md"
            color="dark"
            text={language[props.language].recent_news}
          />
          <FilterSelectNavigator
            className="news-filter-section__select"
            onChange={handleSetCategoryFilterId}
            options={options}
            placeholder={language[props.language].category}
            language={props.language}
            queryParamKey={c.NEWS_POST_PARAM_KEY}
          />
        </div>
        <BodyText
          className="news-filter-section__label"
          size="sm"
          color="dark-gray"
          text={language[props.language].sentences.showingXResults(
            filterNews.slice(0, numberToShow).length
          )}
        />
        <div className="news-filter-section__results">
          {filterNews.slice(0, numberToShow).map((news, index) => {
            return (
              <div
                key={`news-filter-section__results--${index}`}
                className="news-filter-section__result"
              >
                <NewsBannerCard
                  key={`NewsBannerCard--${index}`}
                  language={props.language}
                  news={news}
                />
              </div>
            )
          })}
        </div>
        {numberToShow < filterNews.length && (
          <BrandButton
            className="news-filter-section__cta"
            color="primary"
            onClick={handleViewMore}
            text={language[props.language].view_more}
          />
        )}
      </div>
    </div>
  )
}

NewsFilterSection.propTypes = {
  language: PropTypes.string,
  news: PropTypes.array,
  newsCategories: PropTypes.array,
}
NewsFilterSection.defaultProps = {
  language: "es",
  news: [],
  total: 0,
}

export default NewsFilterSection
