import React from "react"
import PropTypes from "prop-types"
import CustomImage from "@components/common/CustomImage"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import IconLinkButton from "@components/common/IconLinkButton"
import { fullDateFormat } from "@utilities/copyUtils"
import { ReactComponent as ChevronRight } from "@images/icons/chevron-right.svg"
import language from "@language"

import classnames from "classnames"

import "./style.scss"
import UniversalLink from "../../../../components/common/UniversalLink"

const NewsBannerCard = props => {
  const formattedDate = fullDateFormat(
    props.language,
    props.news.acf.displayDate
  )

  return (
    <div className="news-banner-card">
      <div
        className={classnames("row news-banner-card__wrapper", {
          withTopBorder: props.withTopBorder,
        })}
      >
        <div className={`col-md-${props.imageColSize} news-banner-card__head`}>
          <CustomImage
            className="responsive-image news-banner-card__image"
            alt={(props.news.acf.metaImage || {}).altText}
            src={(props.news.acf.metaImage || {}).sourceUrl}
          />
        </div>
        <div
          className={classnames("col-md-6 news-banner-card__content", {
            "offset-md-1": props.imageColSize === 5,
          })}
        >
          {props.news.acf.category && (
            <HeadingText
              className={`news-banner-card__category mb-10px ${props.news.acf.category.acfCategory.color}-text`}
              size="xxxs"
              text={props.news.acf.category.name}
              element="p"
            />
          )}
          <UniversalLink href={props.news.uri}>
            <HeadingText
              className="news-banner-card__title text-link"
              color="primary"
              size="sm"
              text={props.news.title}
            />
          </UniversalLink>
          {formattedDate && (
            <BodyText
              className="news-banner-card__date"
              color="gray"
              size="xs"
              text={formattedDate}
            />
          )}
          {props.news.acf.metaDescription && (
            <BodyText
              className={classnames("news-banner-card__description")}
              size="sm"
              color="dark-gray"
              text={props.news.acf.metaDescription}
            />
          )}

          <IconLinkButton
            className="news-banner-card__cta"
            icon={<ChevronRight />}
            uri={props.news.uri}
            text={language[props.language].read_more}
          />
        </div>
      </div>
    </div>
  )
}

NewsBannerCard.propTypes = {
  language: PropTypes.string,
  withTopBorder: PropTypes.bool,
  imageColSize: PropTypes.oneOf([5, 6]),
  news: PropTypes.shape({
    title: PropTypes.string,
    uri: PropTypes.string,
    acf: PropTypes.shape({
      metaImage: PropTypes.shape({
        sourceUrl: PropTypes.string,
        altText: PropTypes.string,
      }),
      metaDescription: PropTypes.string,
      category: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        color: PropTypes.string,
      }),
      displayDate: PropTypes.string,
    }),
  }),
}
NewsBannerCard.defaultProps = {
  news: {
    acf: {
      metaImage: {},
    },
  },
  imageColSize: 5,
  language: "es",
  withTopBorder: false,
}

export default NewsBannerCard
