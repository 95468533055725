export const DIRECTORY_TABLE_ANCHOR = "dt"
export const LOCATION_TYPE_PARAM_KEY = "t"
export const DEPARTMENT_PARAM_KEY = "d"
export const LOCATION_PARAM_KEY = "l"
export const NEWS_POST_PARAM_KEY = "np"


export const TYPE_HOSPITAL_SLUG = 'hospital'
export const TYPE_CLINIC_SLUG = 'clinica'
export const TYPE_URGENT_SLUG = 'urgencias'
export const TYPE_CENTER_SLUG = 'center'