import React from "react"
import PropTypes from "prop-types"
import BreadcrumbText from "@components/common/BreadcrumbText"
import language from "@language"

import "./style.scss"

const BreadcrumbBand = props => {
  return (
    <div className="breadcrumb-band">
      <div className="breadcrumb-band__wrapper page-wrap">
        <BreadcrumbText
          link1={{ url: "/", title: language[props.language].start }}
          pageTitle={props.pageTitle}
          systemTextSize={props.systemTextSize}
        />
      </div>
    </div>
  )
}

BreadcrumbBand.propTypes = {
  pageTitle: PropTypes.string,
  language: PropTypes.string,
  systemTextSize: PropTypes.oneOf(["sm", "lg"]),
}
BreadcrumbBand.defaultProps = {
  language: "es",
  systemTextSize: "sm",
}

export default BreadcrumbBand
