import React from "react"
import PropTypes from "prop-types"
import language from "@language"
import BreadcrumbBand from "@components/common/BreadcrumbBand"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import { fullDateFormat } from "@utilities/copyUtils"
import IconLinkButton from "@components/common/IconLinkButton"
import CustomImage from "@components/common/CustomImage"
import { ReactComponent as ChevronRight } from "@images/icons/chevron-right.svg"

import moment from "moment"
import "./style.scss"

const FeaturedArticleHero = props => {
  return (
    <div className="featured-article-hero">
      <BreadcrumbBand pageTitle={props.pageTitle} systemTextSize="sm" />
      <div className="featured-article-hero__bottom">
        <div className="page-wrap">
          <div className="row">
            {/* <div className="featured-article-hero__row"> */}
            <div className="featured-article-hero__left col-md-5">
              <CustomImage
                className="featured-article-hero__image mobile-md responsive-image"
                src={(props.news.acf.metaImage || {}).sourceUrl}
                alt={(props.news.acf.metaImage || {}).altText}
              />
              <HeadingText
                className="featured-article-hero__category"
                color="light"
                size="xxxs"
                text={props.news.acf.category.name || props.pageTitle}
                element="p"
              />
              <HeadingText
                className="featured-article-hero__title"
                color="light"
                size="lg2"
                text={props.news.title || props.pageTitle}
                element="h1"
              />
              <BodyText
                className="featured-article-hero__date"
                text={fullDateFormat(
                  props.language,
                  props.news.acf.displayDate || moment.now()
                )}
                color="light"
                size="sm"
              />
              <BodyText
                className={"featured-article-hero__description"}
                size="sm"
                color="light"
                text={props.news.acf.metaDescription}
              />

              <BodyText
                className="featured-article-hero__description"
                text={props.news.acf.description}
                color="light"
                size="md2"
              />
              <IconLinkButton
                icon={<ChevronRight />}
                uri={props.news.uri}
                text={language[props.language].read_more}
                textColor="light"
              />
            </div>

            <div className="offset-md-1 col-md-6">
              <CustomImage
                className="featured-article-hero__image desktop-md responsive-image"
                src={(props.news.acf.metaImage || {}).sourceUrl}
                alt={(props.news.acf.metaImage || {}).altText}
              />
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

FeaturedArticleHero.propTypes = {
  language: PropTypes.string,
  news: PropTypes.shape({
    title: PropTypes.string,
    uri: PropTypes.string,
    acf: PropTypes.shape({
      displayDate: PropTypes.string,
      metaDescription: PropTypes.string,
      metaImage: PropTypes.shape({
        sourceUrl: PropTypes.string,
        altText: PropTypes.string,
      }),
      category: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }),
  pageTitle: PropTypes.string,
}
FeaturedArticleHero.defaultProps = {
  news: {
    acf: {
      category: {},
      metaImage: {},
    },
  },
  language: "es",
}

export default FeaturedArticleHero
