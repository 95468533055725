import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"
import gql from "graphql-tag"

import Layout from "@components/layout"
import SEO from "@components/seo"
import { filterPageForTemplate } from "@templates/utilities/templateUtils"
import FeaturedArticleHero from "./components/FeaturedArticleHero"
import NewsFilterSection from "./components/NewsFilterSection"
import withPreview from "@components/withPreview"

const NewsIndex = props => {
  const page =
    !!props.preview && props.preview.page
      ? props.preview.page.preview.node
      : filterPageForTemplate(
          props.data.allWpPage.edges,
          props.pageContext.wordpressUri
        )

  const newsPosts = props.data.allWpNewsPost.edges.map(e => e.node)
  const newsCategories = props.data.allWpNewsCategory.edges.map(e => e.node)
  return (
    <Layout>
      <SEO title={page.acfPageMeta.metaTitle} />
      <div className="page-fade-in">
        <FeaturedArticleHero
          news={
            page.acfNewsPage.featuredNews &&
            page.acfNewsPage.featuredNews.acf.publishFinal
              ? page.acfNewsPage.featuredNews
              : undefined
          }
          pageTitle={page.acfPageMeta.metaTitle}
        />
        <NewsFilterSection
          news={newsPosts}
          newsCategories={newsCategories}
          language={props.language}
        />
      </div>
    </Layout>
  )
}

NewsIndex.propTypes = {
  language: PropTypes.string,
}

NewsIndex.defaultProps = {
  language: "es",
}

export const query = graphql`
  query NewsPageQuery {
    allWpPage(filter: { acfPageMeta: { pageTemplate: { eq: "news" } } }) {
      edges {
        node {
          uri
          acfPageMeta {
            metaDescription
            metaImage {
              altText
              sourceUrl
              uri
            }
            metaTitle
          }
          acfNewsPage {
            featuredNews {
              ... on WpNewsPost {
                title
                uri
                acf {
                  publishFinal
                  displayDate
                  metaDescription
                  metaImage {
                    sourceUrl
                    altText
                  }
                  authorName
                  category {
                    id
                    name
                    acfCategory {
                      color
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpNewsCategory {
      edges {
        node {
          id
          name
        }
      }
    }
    allWpNewsPost(limit: 250, filter: { acf: { publishFinal: { eq: true } } }) {
      edges {
        node {
          title
          uri
          acf {
            displayDate
            metaDescription
            metaImage {
              sourceUrl
              altText
            }
            authorName
            category {
              id
              name
              acfCategory {
                color
              }
            }
          }
        }
      }
    }
  }
`

// Sorry, have to manually reconstruct the preview queries in the Graphiql tool on wordpress...
const PREVIEW_QUERY = gql`
  query PreviewNewsPageQuery($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      preview {
        node {
          uri
          acfPageMeta {
            metaDescription
            metaImage {
              altText
              sourceUrl
              uri
            }
            metaTitle
          }
          acfNewsPage {
            featuredNews {
              ... on NewsPost {
                title
                uri
                acf {
                  displayDate
                  metaDescription
                  metaImage {
                    sourceUrl
                    altText
                  }
                  authorName
                  category {
                    id
                    name
                    acfCategory {
                      color
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    newsCategories {
      edges {
        node {
          id
          name
        }
      }
    }
    newsPosts(first: 250) {
      edges {
        node {
          title
          uri
          acf {
            displayDate
            metaDescription
            metaImage {
              sourceUrl
              altText
            }
            authorName
            category {
              id
              name
              acfCategory {
                color
              }
            }
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(NewsIndex)
